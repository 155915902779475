<template>
  <div
    class="rounded-lg pa-5 border border-gray-200 overflow-hidden white shadow-light"
  >
    <v-row no-gutters align="center">
      <v-col
        v-if="$store.getters['app/banners'].login_page.img"
        cols="12"
        lg="6"
        order="2"
        order-lg="1"
      >
        <banner
          :loading="false"
          :banner="$store.getters['app/banners'].login_page"
          class="mt-5 mt-lg-0"
        />
      </v-col>
      <v-col cols="12" order="1" order-lg="2" lg="6">
        <div class="px-lg-7">

          <h1 class="text-uppercase lh-1 mb-6">
            <v-row style="margin: 0">
              <span class=" fs-22 fw-700 primary--text mr-2" style="line-height: 44px">{{ $t('login_with') }}</span>
              <span class="d-block display-1 fw-900 primary-vi">
                {{ $store.getters['app/appName'] }}
              </span>
            </v-row>
            <div class="primary--text fs-22 fw-700 mt-7">{{ $t('account') }}</div>
          </h1>

          <v-form ref="loginForm" lazy-validation @submit.prevent="login()">
            
            <div
              v-if="
                authSettings.customer_login_with == 'email' ||
                  (!showPhoneField &&
                    authSettings.customer_login_with == 'email_phone')
              "
              class="mb-4"
            >
              <div class="mb-1 fs-16 fw-500 primary--text">
                {{ $t('email') }}
              </div>

              <v-text-field
                background-color="#FFFBE6"
                v-model="form.email"
                :placeholder="$t('email_address')"
                type="text"
                :error-messages="emailErrors"
                hide-details="auto"
                required
                outlined
              ></v-text-field>
              <div
                v-if="authSettings.customer_login_with == 'email_phone'"
                class="text-end font-italic fs-12 opacity-70"
              >
                <span
                  class="primary--text c-pointer"
                  @click="showPhoneField = !showPhoneField"
                >
                  {{ $t('use_phone_instead') }}
                </span>
              </div>

            </div>

            <div
              v-if="
                authSettings.customer_login_with == 'phone' ||
                  (showPhoneField &&
                    authSettings.customer_login_with == 'email_phone')
              "
              class="mb-4"
            >
              <div class="mb-1 fs-16 fw-500 primary--text">
                {{ $t('phone_number') }}
              </div>
              <vue-tel-input
                v-model="form.phone"
                v-bind="mobileInputProps"
                :preferred-countries="availableCountries"
                :auto-format="false"
                @country-changed="onCountryChanged"
                @validate="phoneValidate"
              >
                <template #arrow-icon>
                  <span style="margin: 0 3px;">{{ dialCode ? `+${dialCode}` : '' }}</span>
                  <span class="vti__dropdown-arrow">&nbsp;▼</span>
                </template>
              </vue-tel-input>
              <div
                v-if="$v.form.phone.$error"
                class="v-text-field__details mt-2 pl-3"
              >
                <div class="v-messages theme--light error--text" role="alert">
                  <div class="v-messages__wrapper">
                    <div class="v-messages__message">
                      {{ $t('this_field_is_required') }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="!$v.form.phone.$error && form.showInvalidPhone"
                class="v-text-field__details mt-2 pl-3"
              >
                <div class="v-messages theme--light error--text" role="alert">
                  <div class="v-messages__wrapper">
                    <div class="v-messages__message">
                      {{ $t('phone_number_must_be_valid') }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="authSettings.customer_login_with == 'email_phone'"
                class="text-end font-italic fs-12 opacity-70"
              >
                <span
                  class="primary--text c-pointer"
                  @click="showPhoneField = !showPhoneField"
                >
                  {{ $t('use_phone_instead') }}
                </span>
              </div>
              <div
                v-if="authSettings.customer_login_with == 'email_phone'"
                class="text-end font-italic fs-12 opacity-70"
              >
                <span
                  class="primary--text c-pointer"
                  @click="showPhoneField = !showPhoneField"
                >
                  {{ $t('use_phone_instead') }}
                </span>
              </div>
            </div>

            <!-- email / phone 切换 -->
            <div class="form-group" style="justify-content: flex-end; display: flex; color: rgba(0,0,0,0.5)">
              <v-btn
                color="#FFFBE6"
                class="p-0 text-reset h-20px mb-2"
                type="button"
                elevation="0"
                @click="switchMode"
              >
                {{
                  authSettings.customer_login_with == 'email'
                    ? $t('change_phone')
                    : $t('change_email')
                }}
              </v-btn>
            </div>

            <div>
              <div class="mb-1 fs-16 fw-500 primary--text">
                {{ $t('password') }}
              </div>
              <v-text-field
                background-color="#FFFBE6"
                v-model="form.password"
                placeholder="* * * * * * * *"
                :error-messages="passwordErrors"
                :type="passwordShow ? 'text' : 'password'"
                :append-icon="passwordShow ? 'las la-eye' : 'las la-eye-slash'"
                class="input-group--focused"
                hide-details="auto"
                required
                outlined
                @click:append="passwordShow = !passwordShow"
              ></v-text-field>
              <div class="text-end font-italic fs-13 opacity-50 mt-2 mb-6">
                <router-link
                  :to="{ name: 'ForgotPassword' }"
                  style="color: #000"
                >
                  {{ $t('forgot_password') }}
                </router-link>
              </div>
            </div>
            <v-btn
              x-large
              block
              class="px-16 mb-4"
              elevation="0"
              type="submit"
              color="primary"
              :loading="loading"
              :disabled="loading"
              @click="login"
            >
              {{ $t('login') }}
            </v-btn>
          </v-form>

          <div
            v-if="
              generalSettings.social_login.facebook == 1 ||
                generalSettings.social_login.twitter == 1 ||
                generalSettings.social_login.google == 1
            "
          >
            <div class="d-flex align-center mb-3">
              <span class="me-2 fs-13 fw-500 opacity-60">
                {{ $t('or') }}, {{ $t('login_with') }}
              </span>
              <v-divider></v-divider>
            </div>
            <SocialLogin class="mb-5" />
          </div>
          
          <div>
            {{ $t('dont_have_an_account') }},
            <router-link
              :to="{ name: 'Registration' }"
              class="primary--text text-decoration-underline"
            >
              {{ $t('signup') }}
            </router-link>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { required, requiredIf, email } from 'vuelidate/lib/validators'
import SocialLogin from '../auth/SocialLogin'
import { VueTelInput } from 'vue-tel-input'
import { mapActions, mapGetters, mapMutations } from 'vuex'



export default {
  data: () => ({
    mobileInputProps: {
      inputOptions: {
        type: 'tel',
        placeholder: 'phone number'
      },
      dropdownOptions: {
        showDialCodeInSelection: false,
        showFlags: true,
        showDialCodeInList: true
      },
      autoDefaultCountry: false,
      validCharactersOnly: true,
      mode: 'international'
    },
    showPhoneField: false,
    form: {
      phone: '',
      email: '',
      password: '',
      invalidPhone: true,
      showInvalidPhone: false,
      verificationCode: ''
    },
    passwordShow: false,
    loading: false,

    // 是否展示切换手机/邮箱开关
    showRegisterTypeSwitch: false,

    // 国家区号
    dialCode: '',

    // 是否需要验证
    customer_register_verify: false
  }),

  components: {
    SocialLogin,
    VueTelInput,
  },

  validations: {
    form: {
      email:{ required, email },

      phone: { required },

      password: {
        required
      },
    }
  },

  computed: {
    ...mapGetters('app', [
      'generalSettings',
      'availableCountries',
      'demoMode',
      'banners',
      'emailVerification'
    ]),
    ...mapGetters('cart', ['getTempUserId']),
    ...mapGetters('auth', ['authSettings']),

    countdownText() {
      return this.countdownSeconds === 60 ? this.$t('send') : `${this.countdownSeconds}s`
    },

    emailErrors() {
      const errors = []

      if (!this.$v.form.email.$dirty) return errors
      !this.$v.form.email.required &&
        errors.push(this.$i18n.t('this_field_is_required'))
      !this.$v.form.email.email &&
        errors.push(this.$i18n.t('this_field_is_required_a_valid_email'))
      return errors
    },
    passwordErrors() {
      const errors = []
      if (!this.$v.form.password.$dirty) return errors
      !this.$v.form.password.required &&
        errors.push(this.$i18n.t('this_field_is_required'))
      return errors
    }
  },
  methods: {
    ...mapActions('auth', {
      actionLogin: 'login'
    }),
    ...mapActions('app', ['fetchProductQuerries']),
    ...mapActions('wishlist', ['fetchWislistProducts']),
    ...mapActions('cart', ['fetchCartProducts']),
    ...mapMutations('cart', ['removeTempUserId']),
    ...mapMutations('auth', ['updateChatWindow', 'showLoginDialog', 'setAuthSettings', 'setUnVerifyEmail']),
    ...mapMutations('app', ['setContactUrl']),

    // 电话区号改变
    onCountryChanged({ dialCode }) {
      this.dialCode = dialCode
    },

    phoneValidate(phone) {
      this.form.invalidPhone = !phone.valid
      this.form.showInvalidPhone = !phone.valid
    },

    // 注册配置
    async registerConfig() {
      try {
        const { data: { data: { customer_register_type, customer_register_verify } } } = await  this.call_api('get', `register/config?tag=customer`)

        switch (customer_register_type) {
          case 2:
            this.setAuthSettingsVerify('phone')
            break
          case 3:
            this.showRegisterTypeSwitch = true
            break
          default:
            break
        }

        this.customer_register_verify = !!customer_register_verify
      } catch (error) {
        console.log('register config error: ', error)
      }
    },

    async login() {
      this.$v.form.$touch()

      if (
        this.$v.form.password.$error
        || (this.authSettings.customer_login_with == 'email' && this.$v.form.email.$error) 
        || (this.authSettings.customer_login_with == 'phone' && this.$v.form.phone.$error) 
      ) {
        return
      }

      if (
        (this.authSettings.customer_login_with == 'phone' ||
          (this.authSettings.customer_login_with == 'email_phone' &&
            this.showPhoneField)) &&
        this.form.invalidPhone
      ) {
        this.form.showInvalidPhone = true
        return
      }
      if (this.getTempUserId) {
        this.form.temp_user_id = this.getTempUserId
      }
      this.form.phone = this.form.phone.replace(/\s/g, '')
      this.loading = true

      const phone = `+${this.dialCode}${this.form.phone}`

      // 如果是电话号码注册，则带上区号
      const params = { ...this.form }
      if (this.authSettings.customer_login_with == 'phone') {
        params.phone = `+${this.dialCode}${this.form.phone}`
        params.email = ''
      } else {
        params.phone = ''
      }

      const res = await this.call_api('post', 'auth/login', params)

      // 邮箱验证
      const isEmail = (this.authSettings.customer_login_with == 'email' ||
            (this.authSettings.customer_login_with == 'email_phone' &&
              this.authSettings.customer_otp_with == 'email'))

      if (res.data.success) {
        isEmail && this.setUnVerifyEmail(params.email)

        if (
          (res.data.email_verified_at ||
          res.data.phone_verified_at) ||
          this.authSettings.customer_otp_with == 'disabled' ||
          !this.customer_register_verify
        ) {
          if (this.getTempUserId) {
            this.removeTempUserId()
          }
          this.actionLogin(res.data)
          this.showLoginDialog(false)
          this.updateChatWindow(false)
          this.fetchWislistProducts()
          this.fetchProductQuerries()
          this.fetchCartProducts()

          // 重新获取客服链接
          const headerResponse = await this.call_api('get', `setting/header`)
          if (headerResponse.status === 200) {
            this.setContactUrl(headerResponse.data.helpline_link)
          }

          this.$router.push(this.$route.query.redirect || { name: 'DashBoard' })
        } else {
          if (
            isEmail
          ) {
            this.$router.push({
              name: 'VerifyAccount',
              params: { email: this.form.email, source: 'login' }
            })
          } else {
            this.$router.push({
              name: 'VerifyAccount',
              params: { phone, source: 'login' }
            })
          }
        }

        this.snack({
          message: res.data.message
        })
      } else {
        this.snack({
          message: res.data.message,
          color: 'red'
        })
      }
      this.loading = false
    },

    // 手机邮箱切换
    switchMode() {
      this.form.phone = ''
      this.form.email = ''

      this.setAuthSettings()
    }
  },
  created() {
    if (this.demoMode) {
      this.form.email = 'customer@example.com'
      this.form.password = '123456'
    }

    this.registerConfig()
  }
}
</script>

<style scoped>
.fc-vi {
  color: #FF3062;
}

.vue-tel-input{
  background: #FFFBE6 !important;
}
</style>
