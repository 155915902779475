<template>
  <div class="logobar">
    <v-container class="pb-md-0 pt-4">
      <div class="d-flex align-center">
        <div class="logo">
          <router-link :to="{ name: 'Home' }" class="d-block lh-0">
            <img
              :src="addResourceImage($store.getters['app/appLogo'])"
              :alt="appName"
              height="60"
              @error="imageFallback($event)"
            />
          </router-link>
        </div>
        <v-spacer />
        <div :class="['flex-grow-1 search-box', { open: openSearch }]">
          <v-form
            class="border rounded-15 flex-grow-1"
            @submit.stop.prevent="search()"
          >
            <v-row align="center" dense>
              <v-col cols="auto ms-1" class="d-md-none">
                <v-btn icon @click.stop="toggleSearch(false)">
                  <i class="la la-arrow-left fs-18 ts-05"></i>
                </v-btn>
              </v-col>

              <v-col cols="auto ms-1" class="position-relative h-100">
                <div @click.stop="closeOnClick = !closeOnClick">
                  <div class="c-pointer fs-15 text-vi">
                    {{
                      itemSelect === 0
                        ? this.$t('shops')
                        : this.$t('seller_product')
                    }}
                    <i class="la la-angle-down fs-12 ts-05" style="margin-left: 10px"></i>
                  </div>
                </div>

                <div
                  class="search-select position-absolute shadow-xs"
                  v-show="closeOnClick"
                >
                  <div class="pt-2 pb-2 c-pointer" @click="toSearchType(1)">
                    {{ this.$t('seller_product') }}
                  </div>
                  <div class="pt-2 pb-2 c-pointer" @click="toSearchType(0)">
                    {{ this.$t('shops') }}
                  </div>
                </div>
              </v-col>
               <v-col>
                <div class="d-flex align-center">
                  <v-text-field
                    :placeholder="$t('search')"
                    type="text"
                    hide-details="auto"
                    class=""
                    v-model="searchKeyword"
                    required
                    solo
                    flat
                  >
                  </v-text-field>
                  <i class="las la-search fs-18 ts-05 d-md-none" style="color: #333" @click="search"></i>
                  <div style="width: 20px"></div>
                </div>
              </v-col>
              <v-col cols="auto me-1" class="d-none d-md-block">
                <v-btn
                  block
                  color="primary"
                  elevation="0"
                  :disabled="!searchKeyword.trim()"
                  @click.stop.prevent="search()"
                >
                  {{ $t('search') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
          <HeaderMenu :loading="loading" :data="data" class="d-none d-lg-block" />
        </div>
        <v-btn
          class="d-md-none border-gray-300"
          fab
          outlined
          small
          @click.stop="toggleSearch(true)"
        >
          <i class="las la-search fs-18 ts-05"></i>
        </v-btn>
        <v-spacer class="d-none d-lg-block" />
        <div class="d-none d-lg-block">
          <div style="height: 48px"></div>
          <div class="login-box mt-5 py-3 px-4">
            <div class="d-flex align-center h-30px" v-if="!isAuthenticated">
              <!-- <i class="las la-user fs-30 lh-1 me-3 opacity-70"></i> -->
              <router-link
                :to="{ name: 'Login' }"
                class="text-white fw-500 fs-20"
              >
                {{ $t('login') }}
              </router-link>
              <span class="mx-3 text-white fw-500 fs-20">{{ $t('or') }}</span>
              <router-link
                :to="{ name: 'Registration' }"
                class="text-white fw-500 fs-20"
              >
                {{ $t('registration') }}
              </router-link>
            </div>
            <div class="d-flex align-center h-30px" v-else>
              <!-- <i class="las la-user fs-30 lh-1 me-3 opacity-70"></i> -->
              <router-link
                :to="{ name: 'DashBoard' }"
                class="text-white fw-500 fs-20"
              >
                {{ $t('dashboard') }}
              </router-link>
              <span class="mx-3 text-white fs-20">{{ $t('or') }}</span>
              <div
                class="text-white fw-500 c-pointer fs-20"
                @click.stop="logout"
              >
                {{ $t('logout') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-container>
    <div
      class="select-makk"
      v-if="closeOnClick"
      @click="closeOnClick = false"
    ></div>

  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import HeaderMenu from './HeaderMenu'

export default {
  props: {
    loading: { type: Boolean, required: true, default: true },
    data: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    openSearch: false,
    searchKeyword: '',
    closeOnClick: false,
    itemSelect: 1
  }),

  components: {
    HeaderMenu
  },

  computed: {
    ...mapGetters('app', ['appLogo', 'appName']),
    ...mapGetters('auth', ['isAuthenticated'])
  },
  methods: {
    ...mapActions(['auth/logout']),
    ...mapActions('cart', ['resetCart']),
    ...mapActions('wishlist', ['resetWishlist']),
    search() {
      if (this.itemSelect === 0) {
        if (!this.searchKeyword.trim()) return
        // 商店
        this.$router
          .replace({
            name: 'AllShops',
            query: {
              page: 1,
              shopName: this.searchKeyword
            }
          })
          .catch(() => {})
      } else {
        // 商品
        this.$router
          .push({
            name: 'Search',
            params:
              this.searchKeyword.length > 0
                ? { keyword: this.searchKeyword }
                : {},
            query: {
              page: 1
            }
          })
          .catch(() => {})
      }
    },
    async logout() {
      await this.call_api('get', 'auth/logout')

      this['auth/logout']()
      this.resetCart()
      this.resetWishlist()
      this.$router.push({ name: 'Home' }).catch(() => {})
    },
    toggleSearch(status) {
      this.openSearch = status
      this.closeOnClick = false
    },

    toSearchType(type) {
      this.itemSelect = type
      this.closeOnClick = false
    }
  }
}
</script>
<style scoped>
.logobar {
  min-height: 68px;
  position: relative;
  z-index: 1;
  padding-bottom: 15px;
}

@media (max-width: 959px) {
  .search-box {
    position: absolute;
    width: calc(100% - 24px);
    padding: 9px 0;
    height: 100%;
    left: 12px;
    right: 12px;
    top: -100%;
    background: #fff;
    display: flex;
    align-items: center;
    z-index: 3;
    transition: top 0.3s;
    -webkit-transition: top 0.3s;
  }
  .search-box.open {
    top: 0px;
  }
}
@media (min-width: 960px) {
  .search-box {
    position: static;
  }
}

.search-select {
  padding: 0 13px;
  z-index: 999;
  left: 0;
  min-width: 80px;
  text-align: center;
  font-size: 12px;
  background-color: #fff;
}

.select-makk {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.rounded-15 {
  border-radius: 15px;
}

.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: #FFE68A !important;
  color: #fff;
  opacity: 0.6;
}
.v-btn__content {
  color: #FFE68A !important;
}

.v-btn {
  border-radius: 50px !important;
}

.v-btn__content {
  color: #FFE68A;
}

.v-btn.v-size--default:not(.v-btn--icon), .v-btn.v-size--default:not(.v-btn--fab) {
  height: 32px !important;
}
body .v-application .primary {
  background-color: #FFE68A !important;
  color: #fff;
}

.login-box {
  border-radius: 20px;
  background: #FFE68A;
}
.text-white {
  color: #fff;
}
</style>
