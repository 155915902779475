<template>
  <div>
    <div class="mobile-bottom-menu d-flex align-center text-center">
      <v-row align="center" dense>
        <v-col>
          <router-link
            class="d-inline-flex align-center flex-column"
            :to="{ name: 'Home' }"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13.484"
              height="15"
              viewBox="0 0 13.484 15"
            >
              <path
                id="Path_18928"
                data-name="Path 18928"
                d="M15.735,6.489h0L11.24,2.548a2.247,2.247,0,0,0-3,0L3.749,6.489A2.247,2.247,0,0,0,3,8.182v6.547a2.247,2.247,0,0,0,2.247,2.247h8.989a2.247,2.247,0,0,0,2.247-2.247V8.174a2.247,2.247,0,0,0-.749-1.685ZM11.24,15.478h-3V11.732a.749.749,0,0,1,.749-.749h1.5a.749.749,0,0,1,.749.749Zm3.746-.749a.749.749,0,0,1-.749.749h-1.5V11.732a2.247,2.247,0,0,0-2.247-2.247h-1.5a2.247,2.247,0,0,0-2.247,2.247v3.746h-1.5a.749.749,0,0,1-.749-.749V8.174a.749.749,0,0,1,.255-.562L9.248,3.679a.749.749,0,0,1,.989,0l4.495,3.933a.749.749,0,0,1,.255.562Z"
                transform="translate(-3 -1.976)"
                fill="#f5a100"
              />
            </svg>
            <span class="d-block fs-10 mt-1 fw-600">{{ $t('home') }}</span>
          </router-link>
        </v-col>
        <v-col>
          <router-link
            class="d-inline-flex align-center flex-column"
            :to="{ name: 'AllCategories' }"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="14.993"
              viewBox="0 0 15 14.993"
            >
              <g
                id="Group_8863"
                data-name="Group 8863"
                transform="translate(-106 -740)"
              >
                <path
                  id="Union_1"
                  data-name="Union 1"
                  d="M-599.5,6.907h-2.22A2.307,2.307,0,0,1-604,4.59V2.324A2.314,2.314,0,0,1-601.724,0h2.22a2.307,2.307,0,0,1,2.276,2.324V4.59a2.344,2.344,0,0,1-.667,1.641,2.247,2.247,0,0,1-1.6.676Zm-2.723-5.091a.933.933,0,0,0-.273.661V4.436a.942.942,0,0,0,.266.674.9.9,0,0,0,.661.271h1.918a.9.9,0,0,0,.647-.28.943.943,0,0,0,.262-.665V2.477a.925.925,0,0,0-.909-.927h-1.934A.9.9,0,0,0-602.227,1.815Z"
                  transform="translate(710 740)"
                  fill="#999"
                />
                <path
                  id="Union_4"
                  data-name="Union 4"
                  d="M-599.5,6.907h-2.22A2.307,2.307,0,0,1-604,4.59V2.324A2.314,2.314,0,0,1-601.724,0h2.22a2.307,2.307,0,0,1,2.276,2.324V4.59a2.344,2.344,0,0,1-.667,1.641,2.247,2.247,0,0,1-1.6.676Zm-2.723-5.091a.933.933,0,0,0-.273.661V4.436a.942.942,0,0,0,.266.674.9.9,0,0,0,.661.271h1.918a.9.9,0,0,0,.647-.28.943.943,0,0,0,.262-.665V2.477a.925.925,0,0,0-.909-.927h-1.934A.9.9,0,0,0-602.227,1.815Z"
                  transform="translate(-483 754.993) rotate(180)"
                  fill="#999"
                />
                <g id="Group_8861" data-name="Group 8861">
                  <path
                    id="Union_3"
                    data-name="Union 3"
                    d="M-601.724,6.9A2.349,2.349,0,0,1-604,4.579V2.32A2.343,2.343,0,0,1-603.333.68,2.247,2.247,0,0,1-601.724,0h.912a.6.6,0,0,1,.3.046.751.751,0,0,1,.507.71.749.749,0,0,1-.37.647.524.524,0,0,1-.414.139h-.788a.924.924,0,0,0-.914.927V4.408a.942.942,0,0,0,.914.927h1.931a.9.9,0,0,0,.646-.269.938.938,0,0,0,.268-.658V1.5l-.008-.072a.552.552,0,0,1,0-.1.751.751,0,0,1-.02-.171A.75.75,0,0,1-598,.41a.747.747,0,0,1,.3.062.608.608,0,0,1,.229.1.632.632,0,0,1,.237.657V4.579A2.3,2.3,0,0,1-599.511,6.9Z"
                    transform="translate(710 748.09)"
                    fill="#999"
                  />
                </g>
                <g
                  id="Group_8862"
                  data-name="Group 8862"
                  transform="translate(227 1494.993) rotate(180)"
                >
                  <path
                    id="Union_3-2"
                    data-name="Union 3"
                    d="M-601.724,6.9A2.349,2.349,0,0,1-604,4.579V2.32A2.343,2.343,0,0,1-603.333.68,2.247,2.247,0,0,1-601.724,0h.912a.6.6,0,0,1,.3.046.751.751,0,0,1,.507.71.749.749,0,0,1-.37.647.524.524,0,0,1-.414.139h-.788a.924.924,0,0,0-.914.927V4.408a.942.942,0,0,0,.914.927h1.931a.9.9,0,0,0,.646-.269.938.938,0,0,0,.268-.658V1.5l-.008-.072a.552.552,0,0,1,0-.1.751.751,0,0,1-.02-.171A.75.75,0,0,1-598,.41a.747.747,0,0,1,.3.062.608.608,0,0,1,.229.1.632.632,0,0,1,.237.657V4.579A2.3,2.3,0,0,1-599.511,6.9Z"
                    transform="translate(710 748.09)"
                    fill="#999"
                  />
                </g>
              </g>
            </svg>
            <span class="d-block fs-10 mt-1 fw-600">
              {{ $t('categories') }}
            </span>
          </router-link>
        </v-col>
        <!-- <v-col>
          <div
            @click.stop="openChatWindow"
            class="c-pointer d-inline-flex align-center flex-column"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="14.094"
              viewBox="0 0 15 14.094"
            >
              <path
                id="Union_6"
                data-name="Union 6"
                d="M-600.21,13.969a1.279,1.279,0,0,1-.74-1.166V11.625a.4.4,0,0,0-.4-.4h-.792A1.857,1.857,0,0,1-604,9.367V1.855A1.858,1.858,0,0,1-602.145,0h11.291A1.857,1.857,0,0,1-589,1.855V9.367a1.857,1.857,0,0,1-1.855,1.855h-4.719a.4.4,0,0,0-.284.117c-.016.015-.032.031-.049.045l-2.932,2.414a1.279,1.279,0,0,1-.817.3A1.289,1.289,0,0,1-600.21,13.969Zm-2.338-12.114V9.367a.4.4,0,0,0,.4.4h.792a1.858,1.858,0,0,1,1.855,1.855v.835l2.646-2.179a1.846,1.846,0,0,1,1.279-.511h4.719a.4.4,0,0,0,.4-.4V1.855a.4.4,0,0,0-.4-.4h-11.291A.4.4,0,0,0-602.548,1.855Zm2.3,5.892A.751.751,0,0,1-601,7a.75.75,0,0,1,.75-.75h7.5A.75.75,0,0,1-592,7a.751.751,0,0,1-.751.751Zm0-3A.75.75,0,0,1-601,4a.75.75,0,0,1,.75-.75h7.5A.75.75,0,0,1-592,4a.75.75,0,0,1-.751.75Z"
                transform="translate(604)"
                fill="#999"
              />
            </svg>
            <span class="d-block fs-10 mt-1 fw-600">{{ $t("messages") }}</span>
          </div>
        </v-col> -->
        <v-col>
          <div
            @click.stop="openCartDrawer"
            class="c-pointer d-inline-flex align-center flex-column"
          >
            <span class="position-relative d-flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12.978"
                height="15"
                viewBox="0 0 12.978 15"
              >
                <path
                  id="Path_18931"
                  data-name="Path 18931"
                  d="M16.476,11.739a2.248,2.248,0,0,0-1.37-2.083l1.348-5.244a.772.772,0,0,0-.13-.652.709.709,0,0,0-.57-.262H5.879l-.238-.944A.726.726,0,0,0,4.942,2H3.5V3.5h.887l1.788,6.937a.725.725,0,0,0,.721.554h7.418a.75.75,0,0,1,0,1.5H4.942a.75.75,0,0,0,0,1.5h.851a2.3,2.3,0,0,0,.742,2.57,2.1,2.1,0,0,0,2.582,0,2.3,2.3,0,0,0,.742-2.57h1.7a2.309,2.309,0,0,0,.554,2.406,2.1,2.1,0,0,0,2.346.419,2.292,2.292,0,0,0,1.007-3.177A2.269,2.269,0,0,0,16.476,11.739ZM13.657,9.491H7.465L6.268,5h8.55ZM7.825,15.484a.75.75,0,1,1,.721-.749A.735.735,0,0,1,7.825,15.484Zm5.767,0a.75.75,0,1,1,.721-.749A.735.735,0,0,1,13.592,15.484Z"
                  transform="translate(-3.5 -2)"
                  fill="#999"
                />
              </svg>
              <span
                class="absolute-top-right red size-15px fs-8 d-flex align-center justify-center rounded-circle white--text"
              >
                {{ getCartCount }}
              </span>
            </span>
            <span class="d-block fs-10 mt-1 fw-600">{{ $t('cart') }}</span>
          </div>
        </v-col>
        <v-col>
          <div
            @click.stop="toggleMobileSideMenu(true)"
            class="c-pointer d-inline-flex align-center flex-column"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 15 15"
            >
              <g id="Page-1" transform="translate(0)">
                <g id="Dribbble-Light-Preview" transform="translate(0)">
                  <g id="icons">
                    <path
                      id="profile_round-_1342_"
                      data-name="profile_round-[#1342]"
                      d="M96.422,2012.5H86.578a.746.746,0,0,1-.721-1,6.3,6.3,0,0,1,11.285,0,.746.746,0,0,1-.721,1m-7.984-9a3.063,3.063,0,1,1,3.062,3,3.035,3.035,0,0,1-3.062-3m10.529,8.727A7.138,7.138,0,0,0,94.378,2007a4.45,4.45,0,0,0,1.662-4.2,4.6,4.6,0,0,0-9.133.7,4.453,4.453,0,0,0,1.717,3.5,7.138,7.138,0,0,0-4.59,5.222A1.5,1.5,0,0,0,85.54,2014H97.459a1.5,1.5,0,0,0,1.508-1.773"
                      transform="translate(-84 -1999)"
                      fill="#999"
                      fill-rule="evenodd"
                    />
                  </g>
                </g>
              </g>
            </svg>
            <span class="d-block fs-10 mt-1 fw-600">{{ $t('account') }}</span>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-navigation-drawer
      class="mobile-side-menu pa-4"
      width="400"
      height="100vh"
      :value="mobileSideMenuOpen"
      @input="toggleMobileSideMenu"
      fixed
      temporary
      hide-overlay
      left
      clipped
    >
      <div>
        <button
          class=""
          @click.stop="toggleMobileSideMenu(false)"
          type="button"
        >
          <i class="la la-close fs-24"></i>
        </button>
      </div>
      <div class="align-center border-bottom d-flex py-5">
        <template v-if="isAuthenticated">
          <v-avatar size="48" class="">
            <img
              :src="addResourceImage(currentUser.avatar)"
              @error="imageFallback($event)"
              class=""
            />
          </v-avatar>
          <div class="ms-3" style="flex: 1">
            <h4 class="fs-14 fw-700 mb-1 lh-1" style="color: #31026D">{{ currentUser.name }}</h4>
            <h4 v-if="showIdSwitch" class="fs-14 fw-700 mb-0 lh-1 text-primary">
              ID: {{ currentUser.id }}
            </h4>
            <div
              class="text-truncate opacity-60 size-md-180px d-flex align-center text-primary"
            >
              {{
                showEmail
                  ? currentUser.email
                    ? currentUser.email
                    : currentUser.phone
                  : '********'
              }}
              <img
                v-show="showEmail"
                @click="showEmail = false"
                src="@/assets/img/user-eye.png"
                width="20px"
                height="20px"
                style="margin-left: 6px"
              />
              <img
                v-show="!showEmail"
                @click="showEmail = true"
                src="@/assets/img/user-eye-close.png"
                width="20px"
                height="20px"
                style="margin-left: 6px"
              />
            </div>
          </div>
        </template>
        <template v-else>
          <v-avatar color="grey lighten-2">
            <i class="las la-user la-2x"></i>
          </v-avatar>
          <div class="ms-3">
            <router-link
              :to="{ name: 'Login' }"
              class="text-reset opacity-80 fw-500 text-primary"
            >
              {{ $t('login') }}
            </router-link>
            <span class="mx-1 opacity-60">{{ $t('or') }}</span>
            <router-link
              :to="{ name: 'Registration' }"
              class="text-reset opacity-80 fw-500 text-primary"
            >
              {{ $t('registration') }}
            </router-link>
          </div>
        </template>
      </div>
      <div class="py-3 border-bottom">
        <v-list class="py-0" dense>
          <v-list-item
            class="flex-grow-0 flex-fill"
            :to="{ name: 'AllCategories' }"
          >
            <v-list-item-icon class="me-4 d-flex align-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="14.993"
                viewBox="0 0 15 14.993"
              >
                <g
                  id="Group_8863"
                  data-name="Group 8863"
                  transform="translate(-106 -740)"
                >
                  <path
                    id="Union_1"
                    data-name="Union 1"
                    d="M-599.5,6.907h-2.22A2.307,2.307,0,0,1-604,4.59V2.324A2.314,2.314,0,0,1-601.724,0h2.22a2.307,2.307,0,0,1,2.276,2.324V4.59a2.344,2.344,0,0,1-.667,1.641,2.247,2.247,0,0,1-1.6.676Zm-2.723-5.091a.933.933,0,0,0-.273.661V4.436a.942.942,0,0,0,.266.674.9.9,0,0,0,.661.271h1.918a.9.9,0,0,0,.647-.28.943.943,0,0,0,.262-.665V2.477a.925.925,0,0,0-.909-.927h-1.934A.9.9,0,0,0-602.227,1.815Z"
                    transform="translate(710 740)"
                    fill="#FFE68A"
                  />
                  <path
                    id="Union_4"
                    data-name="Union 4"
                    d="M-599.5,6.907h-2.22A2.307,2.307,0,0,1-604,4.59V2.324A2.314,2.314,0,0,1-601.724,0h2.22a2.307,2.307,0,0,1,2.276,2.324V4.59a2.344,2.344,0,0,1-.667,1.641,2.247,2.247,0,0,1-1.6.676Zm-2.723-5.091a.933.933,0,0,0-.273.661V4.436a.942.942,0,0,0,.266.674.9.9,0,0,0,.661.271h1.918a.9.9,0,0,0,.647-.28.943.943,0,0,0,.262-.665V2.477a.925.925,0,0,0-.909-.927h-1.934A.9.9,0,0,0-602.227,1.815Z"
                    transform="translate(-483 754.993) rotate(180)"
                    fill="#FFE68A"
                  />
                  <g id="Group_8861" data-name="Group 8861">
                    <path
                      id="Union_3"
                      data-name="Union 3"
                      d="M-601.724,6.9A2.349,2.349,0,0,1-604,4.579V2.32A2.343,2.343,0,0,1-603.333.68,2.247,2.247,0,0,1-601.724,0h.912a.6.6,0,0,1,.3.046.751.751,0,0,1,.507.71.749.749,0,0,1-.37.647.524.524,0,0,1-.414.139h-.788a.924.924,0,0,0-.914.927V4.408a.942.942,0,0,0,.914.927h1.931a.9.9,0,0,0,.646-.269.938.938,0,0,0,.268-.658V1.5l-.008-.072a.552.552,0,0,1,0-.1.751.751,0,0,1-.02-.171A.75.75,0,0,1-598,.41a.747.747,0,0,1,.3.062.608.608,0,0,1,.229.1.632.632,0,0,1,.237.657V4.579A2.3,2.3,0,0,1-599.511,6.9Z"
                      transform="translate(710 748.09)"
                      fill="#FFE68A"
                    />
                  </g>
                  <g
                    id="Group_8862"
                    data-name="Group 8862"
                    transform="translate(227 1494.993) rotate(180)"
                  >
                    <path
                      id="Union_3-2"
                      data-name="Union 3"
                      d="M-601.724,6.9A2.349,2.349,0,0,1-604,4.579V2.32A2.343,2.343,0,0,1-603.333.68,2.247,2.247,0,0,1-601.724,0h.912a.6.6,0,0,1,.3.046.751.751,0,0,1,.507.71.749.749,0,0,1-.37.647.524.524,0,0,1-.414.139h-.788a.924.924,0,0,0-.914.927V4.408a.942.942,0,0,0,.914.927h1.931a.9.9,0,0,0,.646-.269.938.938,0,0,0,.268-.658V1.5l-.008-.072a.552.552,0,0,1,0-.1.751.751,0,0,1-.02-.171A.75.75,0,0,1-598,.41a.747.747,0,0,1,.3.062.608.608,0,0,1,.229.1.632.632,0,0,1,.237.657V4.579A2.3,2.3,0,0,1-599.511,6.9Z"
                      transform="translate(710 748.09)"
                      fill="#FFE68A"
                    />
                  </g>
                </g>
              </svg>
            </v-list-item-icon>
            <v-list-item-title class="fs-13 opacity-90">
              <span class="text-primary">{{ $t('all_categories') }}</span> 
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            class="flex-grow-0 flex-fill"
            :to="{ name: 'AllBrands' }"
          >
            <v-list-item-icon class="me-4 d-flex align-center ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="13"
                viewBox="0 0 15 13"
              >
                <g
                  id="Group_8883"
                  data-name="Group 8883"
                  transform="translate(-35 -10)"
                >
                  <path
                    id="Subtraction_3"
                    data-name="Subtraction 3"
                    d="M-1090,5h-1.5a3.478,3.478,0,0,0-1.026-2.474A3.476,3.476,0,0,0-1095,1.5V0a5.006,5.006,0,0,1,5,5Z"
                    transform="translate(1138 10)"
                    fill="#FFE68A"
                  />
                  <rect
                    id="Rectangle_16244"
                    data-name="Rectangle 16244"
                    width="9"
                    height="1.5"
                    rx="0.75"
                    transform="translate(50 23) rotate(180)"
                    fill="#FFE68A"
                  />
                  <rect
                    id="Rectangle_16241"
                    data-name="Rectangle 16241"
                    width="6"
                    height="1.5"
                    rx="0.75"
                    transform="translate(37 19) rotate(-90)"
                    fill="#FFE68A"
                  />
                  <rect
                    id="Rectangle_16245"
                    data-name="Rectangle 16245"
                    width="6"
                    height="1.5"
                    rx="0.75"
                    transform="translate(37 13)"
                    fill="#FFE68A"
                  />
                  <rect
                    id="Rectangle_16246"
                    data-name="Rectangle 16246"
                    width="6"
                    height="1.5"
                    rx="0.75"
                    transform="translate(42 18.5)"
                    fill="#FFE68A"
                  />
                  <path
                    id="Subtraction_5"
                    data-name="Subtraction 5"
                    d="M-1090,5h-1.5a3.478,3.478,0,0,0-1.026-2.474A3.476,3.476,0,0,0-1095,1.5V0a5.006,5.006,0,0,1,5,5Z"
                    transform="translate(-1053 23) rotate(180)"
                    fill="#FFE68A"
                  />
                  <rect
                    id="Rectangle_16261"
                    data-name="Rectangle 16261"
                    width="9"
                    height="1.5"
                    rx="0.75"
                    transform="translate(35 10)"
                    fill="#FFE68A"
                  />
                  <rect
                    id="Rectangle_16240"
                    data-name="Rectangle 16240"
                    width="6"
                    height="1.5"
                    rx="0.75"
                    transform="translate(48 14) rotate(90)"
                    fill="#FFE68A"
                  />
                </g>
              </svg>
            </v-list-item-icon>
            <v-list-item-title class="fs-13 opacity-90">
             <span class="text-primary">{{ $t('all_brands') }}</span>
            </v-list-item-title>
          </v-list-item>
          <!-- done: 隐藏全部商家按钮 -->
          <!-- <v-list-item
            class="flex-grow-0 flex-fill"
            :to="{ name: 'AllShops' }"
            v-if="is_addon_activated('multi_vendor')"
          >
            <v-list-item-icon class="me-4 d-flex align-center">
              <i class="las la-store-alt"></i>
            </v-list-item-icon>
            <v-list-item-title class="fs-13 opacity-90">{{
              $t("all_shops")
            }}</v-list-item-title>
          </v-list-item> -->
          <v-list-item>
            <a
              :href="contactUrl"
              target="_self"
              class="d-flex flex-grow-0 flex-fill"
            >
              <v-list-item-icon class="me-4 d-flex align-center">
                <i class="las la-map-marked fs-17 text-primary"></i>
              </v-list-item-icon>
              <v-list-item-title class="fs-13 opacity-90">
                <span class="text-primary">{{ $t('help_line_link') }}</span> 
              </v-list-item-title>
            </a>
          </v-list-item>
          <!-- <v-list-item
            class="flex-grow-0 flex-fill"
            :to="{ name: 'TrackOrder' }"
          >
            <v-list-item-icon class="me-4 d-flex align-center">
              <i class="las la-map-marked fs-17"></i>
            </v-list-item-icon>
            <v-list-item-title class="fs-13 opacity-90">{{
              $t("track_order")
            }}</v-list-item-title>
          </v-list-item> -->
        </v-list>
      </div>
      <div class="py-3 border-bottom">
        <v-list class="py-0" dense>
          <UserMenu />
        </v-list>
      </div>
      <div class="py-3 border-bottom">
        <v-list class="py-0" dense>
          <!-- <router-link :to="{ name: 'ShopRegistration' }" class="text-reset">
            <v-list-item
              class="flex-grow-0 flex-fill"
              v-if="allLanguages.length > 1"
            >
                <img src="@/assets/img/be_a_seller.png" class="menu-icon me-4" />

                <v-list-item-title class="fs-13 opacity-90 d-flex align-center">
                  <span>{{ $t('be_a_seller') }}</span>
                  <i class="las la-angle-right ms-auto"></i>
                </v-list-item-title>
            
            </v-list-item>
          </router-link> -->

          <!-- <v-list-item>
            <a :href="$store.getters['app/h5Url']" target="_self" class="d-flex flex-grow-0 flex-fill" style="width: 100%;">
              <img src="@/assets/img/seller_login.png" class="menu-icon me-4" />

              <v-list-item-title class="fs-13 opacity-90 d-flex align-center">
                <span>{{ $t('seller_login') }}</span>
                <i class="las la-angle-right ms-auto"></i>
              </v-list-item-title>
            </a>
          </v-list-item> -->

          <v-list-item
            class="flex-grow-0 flex-fill"
            @click.stop="openLanguageSwitcher"
            v-if="allLanguages.length > 1"
          >
            <v-list-item-icon class="me-4 d-flex align-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 15 15"
              >
                <g
                  id="Group_8874"
                  data-name="Group 8874"
                  transform="translate(-2 -2)"
                >
                  <path
                    id="Path_18953"
                    data-name="Path 18953"
                    d="M9.493,2A7.5,7.5,0,1,0,17,9.5,7.5,7.5,0,0,0,9.493,2Zm5.2,4.5H12.477a11.737,11.737,0,0,0-1.035-2.67A6.022,6.022,0,0,1,14.69,6.5ZM9.5,3.53A10.565,10.565,0,0,1,10.932,6.5H8.068A10.565,10.565,0,0,1,9.5,3.53ZM3.7,11a5.867,5.867,0,0,1,0-3H6.23a12.386,12.386,0,0,0-.105,1.5A12.386,12.386,0,0,0,6.23,11Zm.615,1.5H6.523a11.737,11.737,0,0,0,1.035,2.67A5.99,5.99,0,0,1,4.31,12.5Zm2.213-6H4.31A5.99,5.99,0,0,1,7.557,3.83,11.737,11.737,0,0,0,6.523,6.5ZM9.5,15.47A10.565,10.565,0,0,1,8.068,12.5h2.865A10.565,10.565,0,0,1,9.5,15.47ZM11.255,11H7.745a11.035,11.035,0,0,1-.12-1.5A10.939,10.939,0,0,1,7.745,8h3.51a10.939,10.939,0,0,1,.12,1.5A11.035,11.035,0,0,1,11.255,11Zm.188,4.17a11.737,11.737,0,0,0,1.035-2.67H14.69A6.022,6.022,0,0,1,11.443,15.17ZM12.77,11a12.387,12.387,0,0,0,.1-1.5,12.387,12.387,0,0,0-.1-1.5h2.535a5.867,5.867,0,0,1,0,3Z"
                    fill="#FFE68A"
                  />
                </g>
              </svg>
            </v-list-item-icon>

            <v-list-item-title class="fs-13 opacity-90 d-flex align-center">
              <span class="text-primary">{{ userLanguageObj.name }}</span>
              <i class="las la-angle-right ms-auto text-primary"></i>
            </v-list-item-title>
            <LanguageSwitchDialog ref="langSwitch" />
          </v-list-item>
          <v-list-item
            class="flex-grow-0 flex-fill"
            @click.stop="logout"
            v-if="isAuthenticated"
          >
            <v-list-item-icon class="me-4 d-flex align-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="15"
                viewBox="0 0 12 15"
              >
                <path
                  id="Path_18962"
                  data-name="Path 18962"
                  d="M4,9.5a.75.75,0,0,0,.75.75h5.693L8.717,11.967a.753.753,0,1,0,1.065,1.065l3-3a.775.775,0,0,0,0-1.065l-3-3A.753.753,0,0,0,8.717,7.032L10.443,8.75H4.75A.75.75,0,0,0,4,9.5ZM13.75,2H6.25A2.25,2.25,0,0,0,4,4.25V6.5a.75.75,0,0,0,1.5,0V4.25a.75.75,0,0,1,.75-.75h7.5a.75.75,0,0,1,.75.75v10.5a.75.75,0,0,1-.75.75H6.25a.75.75,0,0,1-.75-.75V12.5a.75.75,0,0,0-1.5,0v2.25A2.25,2.25,0,0,0,6.25,17h7.5A2.25,2.25,0,0,0,16,14.75V4.25A2.25,2.25,0,0,0,13.75,2Z"
                  transform="translate(-4 -2)"
                  fill="#FFE68A"
                />
              </svg>
            </v-list-item-icon>
            <v-list-item-title class="fs-13 opacity-90">
             <span class="text-primary">{{ $t('logout') }}</span>  
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </div>
      <div class="py-3">
        <v-list-item
          class="switch-to-seller"
          :to="{ name: 'ShopRegistration' }"
        >
         <span class="text-primary">{{ $t('switch_to_seller') }}</span> 
          <i class="las la-angle-right ms-auto text-primary"></i>
        </v-list-item>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import UserMenu from '../user/MobileUserMenu'
import LanguageSwitchDialog from './LanguageSwitchDialog'
export default {
  components: {
    LanguageSwitchDialog,
    UserMenu
  },

  data() {
    return {
      data: {},
      showEmail: true
    }
  },

  computed: {
    ...mapGetters('auth', [
      'mobileSideMenuOpen',
      'currentUser',
      'isAuthenticated'
    ]),
    ...mapGetters('cart', ['getCartCount']),
    ...mapGetters('app', [
      'userLanguageObj',
      'allLanguages',
      'h5Url',
      'getSellerH5Url',
      'contactUrl',
      'showIdSwitch'
    ])
  },
  methods: {
    ...mapMutations('auth', [
      'updateChatWindow',
      'updateCartDrawer',
      'updateMobileSideMenu'
    ]),
    ...mapActions(['auth/logout']),
    ...mapActions('cart', ['resetCart']),
    ...mapActions('wishlist', ['resetWishlist']),
    openChatWindow() {
      this.updateChatWindow(true)
    },
    openCartDrawer() {
      this.updateCartDrawer(true)
    },
    toggleMobileSideMenu(status) {
      this.updateMobileSideMenu(status)
    },
    openLanguageSwitcher() {
      this.$refs.langSwitch.open()
    },
    async logout() {
      const res = await this.call_api('get', 'auth/logout')
      console.log(res)
      this['auth/logout']()
      this.resetCart()
      this.resetWishlist()
      this.$router.push({ name: 'Home' }).catch(() => {})
    },
    async getDetails() {
      const res = await this.call_api('get', `setting/header`)
      if (res.status === 200) {
        this.data = res.data
      }
    },

    toSellerLogin() {
      if (
        window.navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        )
      ) {
        window.location.href = this.getSellerH5Url
      } else {
        window.location.href = this.h5Url
      }
    }
  },

  created() {
    this.getDetails()
  }
}
</script>
<style scoped>
.mobile-bottom-menu {
  position: fixed;
  z-index: 600;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 62px;
  background-color: rgba(255, 255, 255, 0.94);
}
.mobile-side-menu {
  z-index: 1610;
}

.menu-icon {
  width: 20px;
  height: 20px;
}

.absolute-top-right {
  top: -10px;
  right: -15px;
}
a.d-inline-flex svg,
a.d-inline-flex svg * {
  fill: #999999;
}
a.router-link-exact-active svg,
a.router-link-exact-active svg * {
  fill: var(--primary);
}
a.d-inline-flex,
.c-pointer.d-inline-flex {
  color: #999999;
}
a.router-link-exact-active {
  color: var(--primary);
}
.switch-to-seller {
  color: #000;
  font-size: 14px;
  font-weight: bold;
}
</style>
